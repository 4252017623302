












































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

@Component({
  components: {
    CoolLightBox,
  },
})
export default class Teacher extends Vue {
  public index = null;

  /* eslint-disable global-require */
  public items = [
    {
      title: 'Продвинутый курс методики преподавания иностранных языков 2024',
      src: require('../assets/img/files/kordik.jpg'),
    },
    {
      title: 'Сертификат CAE 2024',
      src: require('../assets/img/files/CAE_2024.jpg'),
    },
    {
      title: 'Сертификат CAE 2021',
      src: require('../assets/img/files/CAE.png'),
    },
    {
      title: 'Диплом курс CAE  2021',
      src: require('../assets/img/files/диплом курс CAE  2021-1.png'),
    },
    {
      title: 'City&Guilds Exam 2011',
      src: require('../assets/img/files/City&Guilds Exam 2011-1.png'),
    },
    {
      title: 'Курсы синхронного перевода 2009',
      src: require('../assets/img/files/курсы синхронистов 2009.jpg'),
    },
    {
      title: 'Диплом университет 1998',
      src: require('../assets/img/files/Диплом универ 1998-1.png'),
    },
    {
      title: 'Стажировка 1997',
      src: require('../assets/img/files/Стажировка 1997-1.png'),
    },
  ];
}
